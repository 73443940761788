
import { createRoot } from 'react-dom/client';
import { useEffect, useState } from 'react';

import './style.scss';
import Style from './Style';
import Feeds from './Feeds';

// Player for Tiktok
document.addEventListener('DOMContentLoaded', () => {
	const allBlockDirectory = document.querySelectorAll('.wp-block-ttp-tiktok-player');

	allBlockDirectory.forEach(feedsEl => {
		if (!feedsEl.dataset.attributes) {
			return;
		}
		const attributes = JSON.parse(feedsEl.dataset.attributes);
		createRoot(feedsEl).render(<>
			<Style attributes={attributes} clientId={attributes.cId} />
			<Feeds attributes={attributes} isBackend={false} react={{ useEffect, useState }} directory={feedsEl} />
		</>);

		feedsEl?.removeAttribute('data-attributes');
	});
});